import { Component } from 'lib'
import util from 'Common/Util/Util'
import Icon from 'Component/Asset/Icon/Icon'
import locale from 'Common/Locale/Locale'

class EventDate extends Component {
  renderDate (datetime, isPast) {
    const date = locale.translate(Date.parse(datetime), isPast)

    return (
      <div class="date">
        <span class="weekday">{ util.toUpperFirst(date.relativeWeekday) }</span>
        <span class="dash">{ Icon.unicode('dash') }</span>
        <span class="monthday">{ date.dayAndMonth }</span>
      </div>
    )
  }

  render () {
    const date = this.props.date

    return (
      <div class={ this.constructor.name }>
        { this.renderDate(date, this.props.isPast) }
      </div>
    )
  }
}

export default EventDate
