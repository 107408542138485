import { Component, router, $ } from 'lib'
import Image from 'Component/Asset/Image/Image'
import Icon from 'Component/Asset/Icon/Icon'
import util from 'Common/Util/Util'
import locale from 'Common/Locale/Locale'

class EventShow extends Component {
  constructor () {
    super()
    this.addListeners()
  }

  addListeners () {
    this.link = (event) => {
      const link = $(event.currentTarget).attr('href')

      router.go(link, event)
    }
  }

  render () {
    const { league } = this.props.item
    const eventClasses = util.classNames(
      this.props.timeClass,
      'live',
      'event',
      util.toSlug(league.name)
    )

    return (
      <div class={ this.constructor.name }>
        <a
          href={ `/live/${league.slug}` }
          class={ eventClasses }
          style={ Image.background('/bg-live-match.jpg') }
          onclick={ this.link }>
          <div class="top">
            <div class="game">
              <span class="bullet">{ Icon.unicode('bullet') }</span>
              <span class="live-label">{ locale.translate('match.live') }</span>
            </div>
          </div>
          <div class="content">
            <Image class="image" src={ league.image } size="140"/>
            <div class="subtitle">
              { locale.translate('show.title.fallback', {
                leagueName: league.name
              }) || league.name }
            </div>
          </div>
        </a>
      </div>
    )
  }
}

export default EventShow
