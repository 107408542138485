/* eslint max-lines: ["error", {"max": 350, "skipBlankLines": true, "skipComments": true}] */
import { Component, $, router, store, render, Logger } from 'lib'
import EventFilter from 'Component/Event/Filter/EventFilter'
import Event from 'Component/Event/Event'
import EventStickyBar from 'Component/Event/StickyBar/EventStickyBar'
import InformLoading from 'Component/Inform/Loading/InformLoading'
import InformBubble from 'Component/Inform/Bubble/InformBubble'
import relApi from 'Common/Service/RelApi/RelApi'
import util from 'Common/Util/Util'
import google from 'Common/Service/Google/Google'
import {
  leaguePriority,
  selectedPriorities,
  sortLeaguesByPriority
} from '../../Common/Service/RelApi/RelApi'

const scrollTopOffset = 500 // starts pagination for older events (scroll up)
const scrollBotOffset = 1500 // starts pagination for newer events (scroll down)

const leagueFilterKey = 'selected-leagues-schedule'

let previousToken = null // token from previous pagination

class Schedule extends Component {
  constructor () {
    super()
    this.selectedLeagues = [] // hold the list of filtered leagues to show
    this.log = new Logger(this.constructor.name)
  }

  componentDidMount () {
    this.addListeners()
    this.fetchLeagues()
  }

  componentWillUnmount () {
    store.removeListener('liveEvents', this.liveEventsListener)
    store.removeListener('selectedLeagues', this.selectedLeaguesListener)
    store.removeListener(
      'schedule.toggleFilter',
      this.scheduleToggleFilterListener
    )
    $(document).off('scroll', this.scrollListener)
    $(document).off('click', this.clickListener)
    $(document.body).removeClass('no-scroll-small-medium')
  }

  addListeners () {
    store.onChange(
      'liveEvents',
      this.liveEventsListener = (liveEvents, oldLiveEvents) => {
        if (oldLiveEvents) {
          // onChange immediately triggers with the current existing value, skip it. We already have the initial value from the /schedule endpoint
          this.log.info('Live events changed, updating schedule')
          this.fetchSchedule()
        }
      }
    )

    store.onChange(
      'selectedLeagues',
      this.selectedLeaguesListener = (leagues) => {
        this.selectedLeagues = leagues
        window.localStorage.setItem(
          leagueFilterKey,
          JSON.stringify(this.selectedLeagues)
        )
        previousToken = null // a new league was selected/deselected, previous token is now invalid

        // reset schedule to show the loading spinner
        this.setState({ schedule: undefined })
        window.scrollTo(0, 0)
        this.fetchSchedule()
      }
    )

    store.onChange(
      'schedule.toggleFilter',
      this.scheduleToggleFilterListener = (value) => {
        // Prevent the main page from scrolling when sidebar is toggled
        $(document.body).toggleClass('no-scroll-small-medium', value)
        this.setState({ isFilterEnabled: value })
      }
    )

    // first scroll triggers is automatically when the page loads and we
    // force scroll to live or future matches (to hide spoilers). We
    // want to skip this scroll so we don't paginate unnecessarily
    let isAutoScroll = true

    $(document).on(
      'scroll',
      this.scrollListener = () => {
        if (!this.state.schedule) return // should not paginate if schedule is loading
        if (isAutoScroll) {
          isAutoScroll = false
          return
        }

        // reaching the top of the page
        if (window.pageYOffset <= scrollTopOffset) {
          this.fetchPage('older', 'up')
        }
        // reaching the bottom of the page
        else if (
          window.pageYOffset + scrollBotOffset
          >= document.body.scrollHeight
        ) {
          this.fetchPage('newer', 'down')
        }
      }
    )

    $(document).on(
      'click',
      this.clickListener = (event) => {
        const target = $(event.target)

        if (
          store.get('schedule.toggleFilter')
          && target.hasClass('events')
          && target.hasClass('filter-enabled')
        ) {
          store.set('schedule.toggleFilter', false)
        }
      }
    )

    this.scrollUnlockHandler = () => {
      this.scrollLocked = false
    }
  }

  showPageLoading (page) {
    const $events = $(this.base).find('.events')
    const loading = render(<InformLoading style="height:200px"/>)

    page === 'newer' && $events.append(loading)
    page === 'older' && $events.prepend(loading)
  }

  fetchPage (page, direction) {
    if (
      this.scrollLocked
      && this.state.schedule.scrollDirection === direction
    ) {
      // don't let onScroll trigger multiple times while paginating
      // and scrolling to the same direction
      return
    }

    this.scrollLocked = true
    const token = this.state.schedule.pages[page]

    if (token && token !== previousToken) {
      this.showPageLoading(page)
      previousToken = token
      this.fetchSchedule(page, token)
    }
  }

  updateEventsWithPage (page, json) {
    const currentEvents = util.getDeepValue(this, 'state.schedule.events') || []

    if (page === 'older') {
      json.scrollDirection = 'up'
      json.pages.newer = util.getDeepValue(this, 'state.schedule.pages.newer')
      json.events = [...json.events, ...currentEvents] // prepend previous pages
      this.log.info('Adding new page with past events')
    }
    else if (page === 'newer') {
      json.scrollDirection = 'down'
      json.pages.older = util.getDeepValue(this, 'state.schedule.pages.older')
      json.events = [...currentEvents, ...json.events] // append next pages
      this.log.info('Adding new page with future events')
    }
    else {
      // First page load or a new league was selected (user is not scrolling)
      json.scrollDirection = undefined
    }

    return json
  }

  addAllLiveEvents (schedule, page) {
    // Only add live events to the first page.
    // If `page` exists it is not the first page.
    if (page) return schedule

    const live = store.get('liveEvents')

    if (!live || !live.events) return schedule // no live events to add

    // remove events for the selected league
    schedule.events = schedule.events.filter(
      (event) => event.state !== 'inProgress'
    )

    // find the index where unstarted events begin
    let selectedIndex = schedule.events.length

    schedule.events.find((event, index) => {
      if (event.state === 'unstarted') {
        selectedIndex = index
        return true
      }
    })

    // add all live events right before the first unstarted event
    schedule.events.splice(selectedIndex, 0, ...live.events)
    return schedule
  }

  fetchSchedule (page, token) {
    return relApi
      .fetchSchedule(this.selectedLeagues, token)
      .then((json) => {
        const schedule = this.updateEventsWithPage(page, json.schedule)

        this.setState({
          schedule: this.addAllLiveEvents(schedule, page),
          error: false // reset error to false after successful fetch
        })
        this.updateLeaguesQueryParameter()
      })
      .catch((error) => {
        // TODO: retry
        this.updateLeaguesQueryParameter()
        this.setState({ error }) // add error to state
        this.log.error(error)
      })
  }

  fetchLeagues () {
    return relApi
      .fetchLeagues()
      .then((json) => {
        const leagues = json.leagues.sort((leagueA, leagueB) =>
          sortLeaguesByPriority(leagueA, leagueB)
        )

        this.updateSelectedLeagues(leagues)

        const selectedLeaguesData = this.selectedLeagues.map((selectedLeague) =>
          leagues.find((league) => league.id === selectedLeague)
        )

        const leaguesFromStorage = window.localStorage.getItem(leagueFilterKey)

        google.pushSelectedLeagues(
          selectedLeaguesData,
          !!leaguesFromStorage,
          'schedule'
        )

        this.setState({ leagues })
        this.fetchSchedule()
      })
      .catch((error) => {
        // if we can't get leagues, there is nothing to render
        // TODO: retry
        this.setState({ error })
        this.log.error(error)
      })
  }

  updateSelectedLeagues (leagues) {
    const slugs = router
      .query('leagues')
      .split(',')
      .filter(Boolean)

    this.selectedLeagues = []

    if (slugs.length > 0) {
      // we have leagues in the parameter, find leagues with the ids that match that slug
      for (const i in leagues) {
        if (slugs.indexOf(leagues[i].slug) !== -1) {
          this.selectedLeagues.push(leagues[i].id)
        }
      }

      return
    }

    const forcedLeagues = leagues.filter(
      (league) =>
        league.displayPriority?.status === leaguePriority.FORCE_SELECTED
    )

    if (forcedLeagues.length > 0) {
      this.selectedLeagues = forcedLeagues.map((league) => league.id)
    }

    // check local storage
    const previousLeagues = this.getLeaguesFromLocalStorage() || []

    for (const i in leagues) {
      if (
        previousLeagues.indexOf(leagues[i].id) !== -1
        && this.selectedLeagues.indexOf(leagues[i].id) === -1
      ) {
        this.selectedLeagues.push(leagues[i].id)
      }
    }

    if (this.selectedLeagues.length > 0) {
      return
    }

    for (const i in leagues) {
      if (selectedPriorities.includes(leagues[i].displayPriority?.status)) {
        this.selectedLeagues.push(leagues[i].id)
      }
    }

    // if everything failed, select the first league
    if (this.selectedLeagues.length === 0) {
      this.selectedLeagues = [leagues[0].id]
    }
  }

  getLeaguesFromLocalStorage () {
    try {
      return JSON.parse(window.localStorage.getItem(leagueFilterKey))
    }
    catch (ex) {
      this.log.error('Garbage value stored in league filters, removing.')
      window.localStorage.removeItem(leagueFilterKey)

      return []
    }
  }

  updateLeaguesQueryParameter () {
    if (!this.state.leagues) return

    const selected = this.state.leagues.filter(
      (league) => this.selectedLeagues.indexOf(league.id) !== -1
    )

    router.updateUrl(
      window.location.pathname
        + '?leagues='
        + selected.map((league) => league.slug).join(',')
    )
  }

  renderEvents (filterClass) {
    return (
      <div class={ util.classNames('events', filterClass) }>
        <EventStickyBar/>
        <Event
          schedule={ this.state.schedule }
          scrollUnlockHandler={ this.scrollUnlockHandler }
        />
      </div>
    )
  }

  renderErrorOrLoading (error) {
    return (
      <main class={ this.constructor.name }>
        { error ? (
          <InformBubble theme="error" icon="error">
            { error.message }
          </InformBubble>
        )
          : <InformLoading/>
        }
      </main>
    )
  }

  render () {
    if (!this.state.leagues || this.state.error) {
      return this.renderErrorOrLoading(this.state.error)
    }

    const filterClass = this.state.isFilterEnabled ? 'filter-enabled' : ''

    return (
      <main class={ this.constructor.name }>
        { this.state.schedule
          ? this.renderEvents(filterClass)
          : (
            <div class="loading">
              <InformLoading/>
            </div>
          ) }
        <div class={ util.classNames('sidebar', filterClass) }>
          <EventFilter
            leagues={ this.state.leagues }
            selected={ this.selectedLeagues }
          />
        </div>
      </main>
    )
  }
}

export default Schedule
