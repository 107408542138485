import { Component, Logger, router, $ } from 'lib'
import Image from 'Component/Asset/Image/Image'
import Icon from 'Component/Asset/Icon/Icon'
import util from 'Common/Util/Util'
import locale from 'Common/Locale/Locale'

const maxDaysInWeek = 7

class EventWidgetMatch extends Component {
  constructor (props, state) {
    super(props, state)
    this.log = new Logger(this.constructor.name)
    this.addListeners()
  }

  addListeners () {
    this.link = (event) => {
      const link = $(event.currentTarget).attr('href')

      router.go(link, event)
    }
  }

  renderLeagueLogo (league) {
    return (
      <div class="league-logo">
        <Image class="image" src={ league.image } size="36"/>
      </div>
    )
  }

  renderTeams (teams, isLive) {
    const team1 = teams[0]
    const team2 = teams[1]

    return (
      <div class="teams">
        <div class="team">
          <Image class="image" src={ team1.image } size="36"/>
          <span class="code">{ team1.code }</span>
        </div>
        { isLive && <div class="vs">{ locale.translate('match.vs') }</div> }
        <div class="team">
          <Image class="image" src={ team2.image } size="36"/>
          <span class="code">{ team2.code }</span>
        </div>
      </div>
    )
  }

  setCountdownInterval () {
    this.countdownIntervalId = window.setInterval(() => {
      this.forceUpdate() // force recalculation of countdown timer
    }, util.times.SECOND)
  }

  renderHeader (event) {
    if (this.props.isSoon) {
      !this.countdownIntervalId && this.setCountdownInterval()
      const msUntilEvent = Date.parse(event.startTime) - Date.now()

      const timeUntilEvent
        = msUntilEvent / util.times.MINUTE > 60
          ? util.toHHMMSS(msUntilEvent)
          : util.toMMSS(msUntilEvent)

      return (
        <div class="header">
          <span class="soon-indicator">{ locale.translate('date.soon') }</span>
          { msUntilEvent > 0 && <span class="label">{ timeUntilEvent }</span> }
        </div>
      )
    }
    else {
      this.countdownIntervalId && window.clearInterval(this.countdownIntervalId)

      return (
        <div class="header">
          <span class="bullet">{ Icon.unicode('bullet') }</span>
          <span class="label">{ locale.translate('match.live') }</span>
        </div>
      )
    }
  }

  renderLiveMatch (event) {
    const matchClasses = util.classNames(
      'match',
      'live',
      this.props.isSoon && 'soon'
    )

    return (
      <a
        href={ `/live/${event.league.slug}` }
        class={ matchClasses }
        onclick={ this.link }>
        { this.renderHeader(event) }
        { event.type === 'show'
          ? this.renderLeagueLogo(event.league)
          : this.renderTeams(event.match.teams, true) }
        <div class="league">{ event.league.name }</div>
      </a>
    )
  }

  renderFutureMatch (event, isSameDay) {
    const date = locale.translate(Date.parse(event.startTime))
    const time = util.getTimeParts(date.hourAndMinute)
    const matchClasses = util.classNames('match', 'future')

    const daysUntilEvent
      = (Date.parse(event.startTime) - Date.now()) / util.times.DAY

    const dateDisplayText
      = daysUntilEvent > maxDaysInWeek
        ? date.shortDayAndMonth
        : date.relativeWeekday

    return (
      <div class={ matchClasses }>
        { !isSameDay && <div class="date">{ dateDisplayText }</div> }
        { this.renderTeams(event.match.teams) }
        <div class="time">
          <span class="hour">{ time.hour }</span>
          { time.minute && <span class="minute">{ ':' + time.minute }</span> }
          { time.ampm && <span class="ampm">{ time.ampm }</span> }
        </div>
      </div>
    )
  }

  render () {
    if (!this.props.event) {
      return
    }

    const componentClasses = util.classNames(
      this.constructor.name,
      !this.props.isSameDay && 'day-section-start'
    )

    return (
      <div class={ componentClasses }>
        { this.props.isLive || this.props.isSoon
          ? this.renderLiveMatch(this.props.event)
          : this.renderFutureMatch(this.props.event, this.props.isSameDay) }
      </div>
    )
  }
}

export default EventWidgetMatch
