import { Component } from 'lib'
import Image from 'Component/Asset/Image/Image'
import Icon from 'Component/Asset/Icon/Icon'
import util from 'Common/Util/Util'

class EventMatchScore extends Component {
  renderScore (score) {
    return !this.props.hideScore && <div class="score">{ score }</div>
  }

  renderTeam (team) {
    return (
      <div class="team">
        <div class="tricode">{ team.code }</div>
        <div class="logo">
          <Image class="image" src={ team.image } size="36"/>
        </div>
        { this.renderScore(team.result.gameWins) }
      </div>
    )
  }

  assignTeams (teams, game) {
    if (teams && game && game.teams) {
      const blueTeamId = util.getObjectFromKey('side', 'blue', game.teams).id
      const redTeamId = util.getObjectFromKey('side', 'red', game.teams).id

      return {
        blueTeam: util.getObjectFromKey('id', blueTeamId, teams) || teams[0],
        redTeam: util.getObjectFromKey('id', redTeamId, teams) || teams[1]
      }
    }

    return {
      // can only happen if a match is live but a game is not
      blueTeam: teams[0],
      redTeam: teams[1]
    }
  }

  render () {
    // Guarantees we have teams to render
    if (!this.props.teams || this.props.teams.length !== 2) {
      return
    }

    const { blueTeam, redTeam } = this.assignTeams(
      this.props.teams,
      this.props.game
    )

    return (
      <div class={ this.constructor.name }>
        <div class="match">
          <div class="teams">
            { this.renderTeam(blueTeam) }
            <div class="separator">
              { this.props.separator || Icon.unicode('hyphen') }
            </div>
            { this.renderTeam(redTeam) }
          </div>
        </div>
      </div>
    )
  }
}

export default EventMatchScore
