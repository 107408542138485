/* eslint max-lines: ["error", {"max": 300, "skipBlankLines": true, "skipComments": true}] */
import { Component, $, Logger, store } from 'lib'
import EventDate from 'Component/Event/Date/EventDate'
import EventMatch from 'Component/Event/Match/EventMatch'
import EventShow from 'Component/Event/Show/EventShow'
import Icon from 'Component/Asset/Icon/Icon'
import util from 'Common/Util/Util'
import locale from 'Common/Locale/Locale'

const pastEventsToScrollUp = 4 // when clicking "past results", will scroll up and show `pastResultsToScrollUp` past results
const futureEventsToShowFiller = 5 // number of future events to decide if we show the filler or not

let $pastEvents,
  $liveEvents,
  $futureEvents,
  prevScrollPosition = 0

class Event extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.addListeners()
  }

  addListeners () {
    $(document).on(
      'scroll',
      this.scrollListener = () => {
        const $element = this.getElementToScroll()
        const $todayButton = $('.today-button')

        if ($element.offset().top > window.pageYOffset + window.innerHeight) {
          $todayButton
            .removeClass('scroll-up')
            .addClass('scroll-down')
            .css('display', 'block')
        }
        else if ($element.offset().top < window.pageYOffset) {
          $todayButton
            .removeClass('scroll-down')
            .addClass('scroll-up')
            .css('display', 'block')
        }
        else {
          $todayButton.css('display', 'none')
        }
      }
    )
  }

  componentWillUnmount () {
    $(document).off('scroll', this.scrollListener)
    store.removeListener('selectedLeagues', this.selectedLeaguesListener)
  }

  onClickShowResults () {
    $pastEvents = $('.past.event') // make sure this reference is updated
    const totalPastEvents = $pastEvents.length

    if (totalPastEvents <= 0) {
      // if we don't have past results, don't break stuff. Just don't scroll up. This should only happen when a totally new league/region is created and there are no past matches yet.
      return
    }

    const eventIndexToScrollTo = totalPastEvents - pastEventsToScrollUp
    const eventToScrollTo = $pastEvents.eq(eventIndexToScrollTo)

    this.scrollToElementAnimated(eventToScrollTo)
  }

  onClickToday () {
    const elementToScroll = this.getElementToScroll()

    if (!elementToScroll) return
    this.scrollToElementAnimated(elementToScroll, 200)
  }

  /**
   * Scrolls the page so that `$target` is at the top of the page. Animates the
   * scroll smoothly and ensures that the final position is exact.
   * @param {jQuery} $target The Element to scroll to
   */
  scrollToElementAnimated ($target, scrollPx = 5) {
    const eventStickyBar = document.querySelector('.EventStickyBar')
    const topMenu = document.querySelector('.TopMenu')

    let offsets = 0

    if (eventStickyBar && topMenu) {
      offsets = -(eventStickyBar.offsetHeight + topMenu.offsetHeight)
    }
    else {
      this.log.error('TopMenu or EventStickyBar component missing')
    }

    const finalPos = $target.offset().top + offsets

    let currentPos = window.pageYOffset || 0
    const delta = finalPos - currentPos
    const direction = delta === 0 ? 0 : delta / Math.abs(delta)

    const comparison
      = direction < 0
        ? (current, target) => current <= target
        : (current, target) => current >= target

    const scrollUpMs = 5
    const id = setInterval(() => {
      if (comparison(currentPos, finalPos)) {
        clearInterval(id)
      }
      else {
        const amountToScroll = Math.min(
          Math.abs(window.pageYOffset - finalPos),
          scrollPx
        )

        window.scrollTo(0, currentPos += direction * amountToScroll)
      }
    }, scrollUpMs)
  }

  // cannot use JQuery here - breaks on IE11
  scrollToElement ($element) {
    let offset = 0

    const eventStickyBar = document.querySelector('.EventStickyBar')
    const topMenu = document.querySelector('.TopMenu')

    if (eventStickyBar && topMenu) {
      offset = eventStickyBar.offsetHeight + topMenu.offsetHeight
    }
    else {
      this.log.error('TopMenu or EventStickyBar component missing')
    }

    $element.get(0).scrollIntoView(true)
    window.scrollTo(0, window.pageYOffset - offset)
  }

  getElementToScroll () {
    const $filler = $('.filler')

    $liveEvents = $('.live.event')
    $pastEvents = $('.past.event')
    $futureEvents = $('.future.event')

    if ($liveEvents.exists()) {
      // if we have live events, scroll to the first
      return $liveEvents
    }
    else if ($futureEvents.exists()) {
      // no live events, scroll to the first future event header (weekday - month day)
      return $futureEvents.parent().prev()
    }
    else if ($filler.exists()) {
      // no live, no future events. Scroll to filler
      return $filler
    }
    else {
      this.log.warn('Unable to scroll up and hide results (spoilers)')
    }
  }

  hideResults (shouldScroll = true) {
    const elementToScroll = this.getElementToScroll()

    if (!shouldScroll || !elementToScroll) return
    this.scrollToElement(elementToScroll)
  }

  componentWillUpdate () {
    // store current scroll position (to be restored on didUpdate())
    prevScrollPosition = this.base.scrollHeight - window.pageYOffset
  }

  componentDidMount () {
    this.hideResults()
  }

  componentDidUpdate () {
    const scrollDirection = this.props.schedule.scrollDirection

    // when paginating up (scroll up), restore scroll position after adding more events
    if (scrollDirection === 'up') {
      window.scrollTo(0, this.base.scrollHeight - prevScrollPosition)
    }
    // We do nothing when scrolling down. The browser will do its thing and keep the current scroll position
    // When not paginating (added or removed a league to filter selection), hide spoilers
    else if (!scrollDirection) {
      this.hideResults()
    }

    if (scrollDirection) {
      this.props.scrollUnlockHandler && this.props.scrollUnlockHandler()
    }
  }

  getTimeClass (prev, curr, next) {
    // for events that happen at the same time we add different classes for the first, last and hours with a single event to be able to apply various css syles accordingly
    const prevTimestamp = prev && Date.parse(prev.startTime)
    const currTimestamp = curr && Date.parse(curr.startTime)
    const nextTimestamp = next && Date.parse(next.startTime)

    const isFirst = !prev || !util.isSameTime(prevTimestamp, currTimestamp)
    const isLast = !next || !util.isSameTime(currTimestamp, nextTimestamp)

    if (isFirst && isLast) {
      return 'single' // single event happening at this time
    }
    else if (isFirst) {
      return 'first' // multiple events at the same time, this is the first on the list
    }
    else if (isLast) {
      return 'last' // multiple events at the same time, this is the last on the list
    }
    else {
      return 'middle' // multiple events at the same time, this is between other events
    }
  }

  shouldRenderDate (prevItem, item) {
    const isEventNotLive = item.state !== 'inProgress'

    const isNotSameDayAsPrevious = !(
      prevItem
      && util.isSameDay(Date.parse(item.startTime), Date.parse(prevItem.startTime))
    )

    return isEventNotLive && isNotSameDayAsPrevious
  }

  getType (item) {
    const stateMap = {
      completed: 'past',
      inProgress: 'live',
      unstarted: 'future'
    }

    return stateMap[item.state]
  }

  renderEvents (list) {
    let prevItem, prevType
    const elements = []

    list.forEach((item, index, list) => {
      const nextItem = list[index + 1]
      const timeClass = this.getTimeClass(prevItem, item, nextItem)
      const type = this.getType(item)

      if (prevType !== type) {
        elements.push(<div class={ 'divider ' + type }/>)
      }

      if (this.shouldRenderDate(prevItem, item)) {
        elements.push(
          <EventDate date={ item.startTime } isPast={ type === 'past' }/>
        )
      }

      prevItem = item
      prevType = type

      if (item.type === 'match') {
        elements.push(
          <EventMatch
            key={ item.id }
            item={ item }
            timeClass={ timeClass }
            hasVod={ item.match.flags && item.match.flags.includes('hasVod') }
          />
        )
      }
      else if (item.type === 'show') {
        elements.push(
          <EventShow key={ item.id } item={ item } timeClass={ timeClass }/>
        )
      }
    })

    return elements
  }

  getEventsInPhase (events, phase) {
    return events.filter(({ state }) => state === phase)
  }

  renderScrollToPastEvents (results) {
    // don't render the past results link if there are no past results
    if (results.length === 0) {
      return
    }

    return (
      <div
        class="results-label"
        onclick={ () => this.onClickShowResults() }
        role="button">
        { locale.translate('schedule.pastResults') }
        { Icon.unicode('space') }
        <Icon name="scrollUp"/>
      </div>
    )
  }

  renderScrollToToday () {
    return (
      <div
        class={ 'today-button scroll-up' }
        onclick={ () => this.onClickToday() }
        role="button">
        { locale.translate('date.today') }
        { Icon.unicode('space') }
        <Icon name="scrollUp"/>
      </div>
    )
  }

  renderNoFutureEvents (futureEventsLength) {
    // don't render filler if we have enough events
    if (futureEventsLength > futureEventsToShowFiller) {
      return
    }

    // show the message only if we don't have future events
    const showMessage = futureEventsLength === 0

    return (
      <div class="filler">
        { showMessage && locale.translate('message.noFutureScheduledEvents') }
      </div>
    )
  }

  renderNoEvents () {
    return (
      <div class={ this.constructor.name }>
        <div class="filler">
          { locale.translate('message.noScheduledEvents') }
        </div>
      </div>
    )
  }

  render () {
    const events = this.props.schedule.events
    const live = this.getEventsInPhase(events, 'inProgress')
    const future = this.getEventsInPhase(events, 'unstarted')
    const results = this.getEventsInPhase(events, 'completed')

    if (events.length === 0) {
      return this.renderNoEvents()
    }

    return (
      <div class={ this.constructor.name }>
        { this.renderScrollToToday() }
        { this.renderEvents(results) }
        { this.renderEvents(live) }
        { this.renderScrollToPastEvents(results) }
        { this.renderEvents(future) }
        { this.renderNoFutureEvents(future.length) }
      </div>
    )
  }
}

export default Event
