import { Component, Logger } from 'lib'
import Image from 'Component/Asset/Image/Image'

class EventShowHeader extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
  }

  render () {
    if (!this.props.league) {
      this.log.error('League prop required')
      return
    }

    return (
      <div class={ this.constructor.name }>
        <div class="league">
          <Image class="logo" src={ this.props.league.image } size="36"/>
          <div class="name">{ this.props.league.name }</div>
        </div>
      </div>
    )
  }
}

export default EventShowHeader
