import { Component, store } from 'lib'
import Image from 'Component/Asset/Image/Image'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import google from 'Common/Service/Google/Google'
import analytics from 'Common/Service/Analytics/Analytics'

class EventFilter extends Component {
  componentWillMount () {
    this.addListeners()
  }

  isSelected (id) {
    return this.props.selected.indexOf(id) !== -1
  }

  filter (leagues) {
    store.set('selectedLeagues', leagues)
  }

  addListeners () {
    this.onClickSave = () => {
      store.set('schedule.toggleFilter', false)
    }
  }

  selectLeague (id) {
    const { selected, leagues } = this.props
    const selectedLeagues = selected
    const isSelected = this.isSelected(id)

    const clickedLeague = leagues.find((league) => league.id === id)

    if (isSelected) {
      // already selected, deselect
      if (selectedLeagues.length === 1) {
        // don't let deselect all leagues
        return
      }
      const index = selectedLeagues.indexOf(id)

      selectedLeagues.splice(index, 1)
      analytics.trackEvent('schedule_filter_removed', {
        league: clickedLeague.slug,
        leagueName: clickedLeague.name
      })
    }
    else {
      selectedLeagues.push(id)
      analytics.trackEvent('schedule_filter_applied', {
        league: clickedLeague.slug,
        leagueName: clickedLeague.name
      })
    }

    const selectedLeaguesData = selectedLeagues.map((selectedLeague) =>
      leagues.find((league) => league.id === selectedLeague)
    )

    google.pushSelectedLeagues(selectedLeaguesData, true, 'schedule')

    this.filter(selectedLeagues)
  }

  renderLeagues (leagues) {
    return leagues.map((league) => {
      const filterOptionClasses = util.classNames(
        'button',
        'league',
        this.isSelected(league.id) && 'selected'
      )

      return (
        <button
          class={ filterOptionClasses }
          onclick={ () => this.selectLeague(league.id) }>
          <div class="info">
            <Image class="image" src={ league.image } size="60"/>
            <div class="label">
              <div class="name">{ league.name }</div>
              <div class="region">{ league.region }</div>
            </div>
          </div>
        </button>
      )
    })
  }

  render () {
    const leagues = this.props.leagues

    return (
      <div class={ this.constructor.name }>
        <div class="header">
          <div class="filter">{ locale.translate('schedule.filter') }</div>
          <div class="save" onclick={ this.onClickSave } role="button">
            { locale.translate('navigation.save') }
          </div>
        </div>
        <div class="leagues">{ this.renderLeagues(leagues) }</div>
      </div>
    )
  }
}

export default EventFilter
