import { Component, store, $ } from 'lib'
import locale from 'Common/Locale/Locale'

class EventStickyBar extends Component {
  componentWillMount () {
    this.addListeners()
  }

  addListeners () {
    this.onClickFilter = () => {
      const toggle = !store.get('schedule.toggleFilter')

      store.set('schedule.toggleFilter', toggle)
    }

    $(document).on(
      'click',
      this.clickListener = (event) => {
        // close the filter unless its the filter button, or a league
        if (
          store.get('schedule.toggleFilter')
          && !$(event.target).closest('#sticky-bar-filter').length
          && !$(event.target).closest('.league').length
        ) {
          store.set('schedule.toggleFilter', false)
        }
      }
    )
  }

  componentWillUnmount () {
    $(document).off('click', this.clickListener)
  }

  render () {
    return (
      <div class={ this.constructor.name }>
        <div id="sticky-bar-date"/>

        <div id="sticky-bar-filter" onclick={ this.onClickFilter } role="button">
          { locale.translate('schedule.filter') }
        </div>
      </div>
    )
  }
}

export default EventStickyBar
