import { Component, router, $, Logger } from 'lib'
import Image from 'Component/Asset/Image/Image'
import Icon from 'Component/Asset/Icon/Icon'
import util from 'Common/Util/Util'
import locale from 'Common/Locale/Locale'
import EventTime from 'Component/Event/Time/EventTime'
import InformSpoiler from 'Component/Inform/Spoiler/InformSpoiler'

class EventMatch extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.addListeners()
  }

  componentWillMount () {
    // TODO: use definitive way to determine spoiler-potential matches
    this.setState({
      hideSpoilers: false,
      showBlur: false
    })
  }

  addListeners () {
    this.link = (event) => {
      const link = $(event.currentTarget).attr('href')

      router.go(link, event)
    }

    this.showSpoilersHandler = () => {
      this.setState({ hideSpoilers: false })
    }
  }

  renderScore (scoreTeam1, scoreTeam2) {
    return (
      <div class="score">
        <span class="scoreTeam1">{ scoreTeam1 }</span>
        <span class="hyphen">-</span>
        <span class="scoreTeam2">{ scoreTeam2 }</span>
      </div>
    )
  }

  getWinner (team1, team2) {
    if (team1.result.outcome === 'win') {
      return 'team1'
    }
    else if (team2.result.outcome === 'win') {
      return 'team2'
    }

    return 'tie'
  }

  getGameNumber (match) {
    // + 1 as wins are only from results
    const gameNumber
      = match.teams[0].result.gameWins + match.teams[1].result.gameWins + 1

    return match.strategy.type === 'bestOf' && gameNumber > match.strategy.count
      ? match.strategy.count
      : gameNumber
  }

  renderMatch (match) {
    const team1 = match.teams[0]
    const team2 = match.teams[1]

    const showRecord = match.strategy.type !== 'playAll'

    // When the winner is none, it will look like a future game
    // It will create a winner none, which matches nothing which defaults
    // to white and no faded icons
    const matchClassNames = util.classNames(
      this.isPast ? 'teams winner-' + this.getWinner(team1, team2) : 'teams',
      this.state.hideSpoilers && 'hide-spoilers'
    )

    return (
      <div class={ matchClassNames }>
        { this.renderTeam(team1, 'team1', showRecord) }
        { this.isPast
          ? this.renderScore(team1.result.gameWins, team2.result.gameWins)
          : <div class="versus">{ locale.translate('match.vs') } </div>
        }
        { this.renderTeam(team2, 'team2', showRecord) }
        <InformSpoiler
          visible={ this.state.hideSpoilers }
          showSpoilersHandler={ this.showSpoilersHandler }
        />
      </div>
    )
  }

  renderRecord (record) {
    // We might not have a record, and shouldn't render the record
    // for live matches or future matches for spoiler protection.
    if (!record || this.isLive || this.isFuture) {
      return
    }

    const { wins, losses } = record

    return (
      <div class="winloss">
        { locale.translate('match.wins', { wins }) }
        { Icon.unicode('hyphen') }
        { locale.translate('match.losses', { losses }) }
      </div>
    )
  }

  renderTeam (team, slug, showRecord) {
    const { name, code, image } = team

    return (
      <div class={ 'team ' + slug }>
        <Image src={ image } size="70"/>
        <div class="team-info">
          <h2>
            <span class="name">{ name }</span>
            <span class="tricode">{ code }</span>
          </h2>

          { showRecord && this.renderRecord(team.record) }
        </div>
      </div>
    )
  }

  renderStrategy (strategy) {
    return (
      strategy
      && strategy.count && (
        <div class="strategy">
          { strategy.type === 'bestOf'
            ? locale.translate('match.bestOf')
            : locale.translate('match.playAll') }
          { Icon.unicode('space') }
          { strategy.count }
        </div>
      )
    )
  }

  renderLeague (item) {
    return (
      <div
        role="button"
        class="league"
        onclick={ () => this.setState({ showBlur: !this.state.showBlur }) }>
        <div class="name">{ item.league.name }</div>
        { this.renderStrategy(item.match.strategy) }
      </div>
    )
  }

  renderNoVodMessage () {
    return (
      <div
        role="button"
        class="no-vod"
        onclick={ () => this.setState({ showBlur: !this.state.showBlur }) }>
        <div class="message">{ locale.translate('message.vodNotAvailable') }</div>
      </div>
    )
  }

  renderPastOrFuture (item) {
    // past and future matches are very similar. Render them with the same methods
    const phase = this.isPast ? 'past' : 'future'
    const classes = util.classNames(
      this.props.timeClass,
      phase,
      'event',
      this.state.showBlur && 'blur-enabled'
    )

    let onClickEvent

    if (this.isPast) {
      if (!this.props.hasVod) {
        onClickEvent = () => this.setState({ showBlur: !this.state.showBlur })
        return (
          <div class={ classes } onclick={ onClickEvent } role="button">
            <EventTime time={ item.startTime }/>
            { this.renderMatch(item.match) }
            { this.renderLeague(item) }
          </div>
        )
      }
      else {
        onClickEvent = (event) => this.link(event)
        const url = `/vod/${item.match.id}/1` // link to first game of VOD

        return (
          <a class={ classes } href={ url } onclick={ onClickEvent }>
            <EventTime time={ item.startTime }/>
            { this.renderMatch(item.match) }
            { this.renderLeague(item) }
          </a>
        )
      }
    }
    else if (this.isFuture) {
      return (
        <div class={ classes }>
          <EventTime time={ item.startTime } approx/>
          { this.renderMatch(item.match) }
          { this.renderLeague(item) }
        </div>
      )
    }
    else {
      this.log.error('Match ' + item.id + ' has unknown phase.')
    }
  }

  renderLive (item) {
    const eventClasses = util.classNames(
      this.props.timeClass,
      'link',
      'live',
      'event',
      util.toSlug(item.league.name)
    )

    return (
      <a
        href={ `/live/${item.league.slug}` }
        class={ eventClasses }
        style={ Image.background('/bg-live-match.jpg') }
        onclick={ (event) => this.link(event) }>
        <div class="top">
          <div class="game">
            <span class="bullet">{ Icon.unicode('bullet') }</span>
            <span class="live-label">{ locale.translate('match.live') }</span>
            <span class="game-number">
              { locale.translate('match.gameNumber', {
                game: this.getGameNumber(item.match)
              }) }
            </span>
          </div>
          <div class="league">
            <span class="name">{ item.league.name }</span>
            <span class="hyphen">
              { item.blockName && Icon.unicode('spacedHyphen') }
            </span>
            <span class="round">{ item.blockName }</span>
          </div>
          <div class="viewers"/>
        </div>
        { this.renderMatch(item.match) }
      </a>
    )
  }

  render () {
    const item = this.props.item

    this.isPast = item.state === 'completed'
    this.isFuture = item.state === 'unstarted'
    this.isLive = item.state === 'inProgress'

    if (this.isPast && !item.match.teams[0].result) return

    return (
      <div class={ this.constructor.name }>
        { this.state.showBlur && this.renderNoVodMessage() }
        { this.isLive ? this.renderLive(item) : this.renderPastOrFuture(item) }
      </div>
    )
  }
}

export default EventMatch
