import { Component } from 'lib'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'

class EventTime extends Component {
  renderTime (dateTime, isApprox) {
    const date = locale.translate(Date.parse(dateTime))
    const time = util.getTimeParts(date.hourAndMinute)

    return (
      <div class="time">
        <span class="hour">{ time.hour }</span>
        { time.minute && <span class="minute">{ time.minute }</span> }
        { time.ampm && <span class="ampm">{ time.ampm }</span> }
        { isApprox
          && <span class="approx">{ locale.translate('schedule.approx') }</span>
        }
      </div>
    )
  }

  render () {
    const time = this.props.time

    return (
      <div class={ this.constructor.name }>
        { this.renderTime(time, this.props.approx) }
      </div>
    )
  }
}

export default EventTime
