/* eslint max-lines: ["error", {"max": 300, "skipBlankLines": true, "skipComments": true}] */
import { Component, $, Logger, store } from 'lib'
import InformLoading from 'Component/Inform/Loading/InformLoading'
import EventWidgetMatch from 'Component/Event/Widget/Match/EventWidgetMatch'
import Icon from 'Component/Asset/Icon/Icon'
import util from 'Common/Util/Util'
import locale from 'Common/Locale/Locale'
import relApi from 'Common/Service/RelApi/RelApi'

let $eventList, $arrowLeft, $arrowRight, $this

// element widths do not change between sizes in EventWidgetMatch,
// hardcoding values here to avoid excess element selection using $
const futureEventWidth = 150
const liveEventWidth = 240
const arrowWidth = 50

// only display a soon event if it falls inbetween these two hour values
const soonEventMinHourThreshold = -0.5
const soonEventMaxHourThreshold = 1.5

class EventWidget extends Component {
  constructor (props) {
    super(props)
    this.log = new Logger(this.constructor.name)

    this.addListeners()
  }

  addListeners () {
    $(window).on(
      'resize',
      this.onResize = () => {
        // When there are no future events or the widget is still loading,
        // shouldn't try to set arrow visibility
        if (!$eventList || !$eventList.length) return

        // on window resize, re-set arrows
        const { left, right } = this.getEventListPosition()

        this.setArrowVisibility(left, right)
      }
    )

    store.onChange(
      'liveEvents',
      this.liveEventsListener = (json, oldJson) => {
        // Need to refetch to ensure live matches are not shown as unstarted.
        // Do not refetch on the first onChange (going from null to a json).
        // We are already fetching for the first time on componentDidMount
        oldJson && this.fetchEventList()

        const teamLiveEvent = this.getLiveEventForTeam(json.events)

        teamLiveEvent
          ? this.setState({ liveEvents: [teamLiveEvent] })
          : this.setState({ liveEvents: json.events })
      }
    )

    this.scrollLeft = () => {
      let { left, right } = this.getEventListPosition()
      const widthToScroll = this.getWidthToScroll(left, 'left')

      left += widthToScroll
      right -= widthToScroll
      if (left > 0) {
        // if scrolled too far, set to max value
        left = 0
      }

      $eventList.css('left', left + 'px')
      this.setArrowVisibility(left, right)
    }

    this.scrollRight = () => {
      let { left, right } = this.getEventListPosition()
      const widthToScroll = this.getWidthToScroll(left, 'right')

      left -= widthToScroll
      right += widthToScroll
      if (right > 0) {
        // if scrolled too far, set to max value
        left = left + right - right % futureEventWidth
      }

      $eventList.css('left', left + 'px')
      this.setArrowVisibility(left, right)
    }

    this.onSettingsClick = () => {
      this.props.onSettingsClick && this.props.onSettingsClick()
    }
  }

  componentWillUnmount () {
    $(window).off('resize', this.onResize)
    store.removeListener('liveEvents', this.liveEventsListener)
  }

  componentDidUpdate (prevProps) {
    this.refetchIfNewProps(prevProps)

    $this = $(this.base)
    $eventList = $('#event-list')

    if ($eventList.length === 0) {
      return
    }

    $arrowLeft = $('#event-list-arrow-left')
    $arrowRight = $('#event-list-arrow-right')

    if (!util.isTouchDevice()) {
      // show arrows for NON-touch devices
      const { left, right } = this.getEventListPosition()

      this.setArrowVisibility(left, right)
    }
    else {
      // touch devices will touch-scroll. Remove arrows
      $this.css('overflow', 'scroll')
      this.setArrowVisibility(0, 0)
    }
  }

  componentDidMount () {
    this.fetchEventList()
  }

  refetchIfNewProps (prevProps) {
    if (!prevProps || !prevProps.leagueIds || !this.props.leagueIds) return

    !util.arraysContainSameItems(this.props.leagueIds, prevProps.leagueIds)
      && this.fetchEventList()
  }

  fetchEventList () {
    // Fetch for either a specific team's matches or for all teams that are
    // a part of some selected league
    return relApi
      .fetchEventList(this.props)
      .then((json) => {
        this.setState({
          futureEvents: json
        })
      })
      .catch((error) => {
        this.setState({ error })
        this.log.error(error)
      })
  }

  getLiveEventsForLeagues (events) {
    if (!events || !this.props.leagueIds) return

    for (let i = 0; i < events.length; i++) {
      const leagueSlug = util.getDeepValue(events[i], 'league.id')

      if (this.props.leagueIds.indexOf(leagueSlug) !== -1) {
        return events[i]
      }
    }
  }

  getLiveEventForTeam (events) {
    if (!events || !this.props.teamSlug) return

    for (let i = 0; i < events.length; i++) {
      const team1Slug = util.getDeepValue(events[i], 'match.teams.0.slug')
      const team2Slug = util.getDeepValue(events[i], 'match.teams.1.slug')

      if (
        team1Slug === this.props.teamSlug
        || team2Slug === this.props.teamSlug
      ) {
        return events[i]
      }
    }
  }

  setArrowVisibility (left, right) {
    // show/hide arrows depending on the left and right positions of the container
    const displayRight = right < 0 ? 'flex' : 'none' // right arrow display state

    $arrowRight.css('display', displayRight)

    const displayLeft = left < 0 ? 'flex' : 'none' // left arrow display state

    $arrowLeft.css('display', displayLeft)
  }

  getEventListPosition () {
    // get the current left and right css element position
    const left = Number($eventList.css('left').replace('px', ''))
    const right = $eventList.parent().width() - $eventList.width() - left

    return { left, right }
  }

  getVisibleEventCount () {
    const { left, right } = this.getEventListPosition()
    const visibleEventListWidth = $eventList.width() - arrowWidth - left + right

    return Math.round(visibleEventListWidth / futureEventWidth)
  }

  getWidthToScroll (left, direction) {
    const eventsToScroll = parseInt($this.width() / futureEventWidth - 1, 10) // scroll a "page" minus one (-1) event

    let widthToScroll = eventsToScroll * futureEventWidth

    if (left === 0) {
      widthToScroll -= arrowWidth // no left arrow, subtract it's width

      const liveEventElements = $eventList.find('.match.live')

      if (liveEventElements.length) {
        // correctly calculate scroll width based on live event width
        widthToScroll -= futureEventWidth * liveEventElements.length
        widthToScroll += liveEventWidth * liveEventElements.length
      }
    }

    // if we would be stuck in the middle of the live tile, scroll all the way left
    if (
      direction === 'left'
      && Math.abs(left + widthToScroll) < futureEventWidth
    ) {
      widthToScroll = -left
    }

    return widthToScroll
  }

  renderSoonMatch (event) {
    return <EventWidgetMatch event={ event } isSoon/>
  }

  renderFutureMatch (event, isSameDay) {
    const hoursUntilEvent
      = (Date.parse(event.startTime) - Date.now()) / util.times.HOUR

    if (
      hoursUntilEvent > soonEventMinHourThreshold
      && hoursUntilEvent < soonEventMaxHourThreshold
    ) {
      return this.renderSoonMatch(event)
    }

    return <EventWidgetMatch event={ event } isSameDay={ isSameDay }/>
  }

  renderLiveEvents (liveEvents) {
    return liveEvents.map((event) => <EventWidgetMatch event={ event } isLive/>)
  }

  renderFutureEvents (futureEvents) {
    let prevEvent

    return futureEvents.map((event) => {
      let isSameDay = false

      if (prevEvent) {
        isSameDay = util.isSameDay(
          Date.parse(event.startTime),
          Date.parse(prevEvent.startTime)
        )
      }
      prevEvent = event
      return this.renderFutureMatch(event, isSameDay)
    })
  }

  renderSettingsCog () {
    return (
      <div
        class="settings"
        onClick={ this.onSettingsClick }
        role="button"
        aria-label={ locale.translate('navigation.settings') }>
        <Icon name="cog"/>
      </div>
    )
  }

  renderEventList (liveEvents, futureEvents) {
    return (
      <div id="event-list">
        { liveEvents && this.renderLiveEvents(liveEvents) }
        { this.renderFutureEvents(futureEvents) }
      </div>
    )
  }

  renderArrow (direction, short) {
    const onClick = direction === 'left' ? this.scrollLeft : this.scrollRight
    const classes = util.classNames('arrow', direction, short && 'short')

    return (
      <div
        id={ `event-list-arrow-${direction}` }
        role="button"
        class={ classes }
        onClick={ onClick }
        aria-label={ locale.translate('navigation.more') }>
        <Icon name="arrow" direction={ direction }/>
      </div>
    )
  }

  renderNoEvents () {
    return (
      <div class="message">
        { locale.translate('message.noFutureScheduledEvents') }
      </div>
    )
  }

  renderLoading () {
    return (
      <main class={ this.constructor.name }>
        <InformLoading style="height: 100%"/>
      </main>
    )
  }

  render () {
    const { futureEvents, liveEvents, error } = this.state

    if (error) return
    if (!futureEvents) return this.renderLoading()

    const hasFutureEvents = futureEvents && futureEvents.length
    const hasLiveEvents = liveEvents && liveEvents.length
    const shouldRenderEvents = !!(hasFutureEvents || hasLiveEvents)
    const shouldRenderSettings = this.props.onSettingsClick
    const classes = util.classNames(
      this.constructor.name,
      !shouldRenderEvents && 'no-events'
    )

    return (
      <div class={ classes }>
        { shouldRenderEvents
          ? this.renderEventList(liveEvents, futureEvents)
          : this.renderNoEvents() }
        { shouldRenderSettings && this.renderSettingsCog() }
        { shouldRenderEvents && this.renderArrow('left') }
        { shouldRenderEvents && this.renderArrow('right', shouldRenderSettings) }
      </div>
    )
  }
}

export default EventWidget
